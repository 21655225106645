<template>
    <div>
      <el-dialog v-loading="loading" :title="type == 'create'||type == 'anewcreate' ? '提交追差申请' : '追差申请详情'" :visible.sync="visible" :close-on-click-modal="false" width="900px">
        <div class="box">
          <el-row>
            <el-col :span="13">
              <div class="title">订单信息</div>
              <div class="info-item">
                <span class="mr-30">{{ info.phoneName }}</span>
                <span>IMEI：{{ info.imei }}</span>
                <el-link type="primary" :underline="false" class="ml-10" @click="$refs['functionDia'].open(row)">查看功能情况</el-link>
              </div>
              <div class="info-item">
                <span class="mr-30">回收商实报价：{{info.quotePrice}} </span> 
                <span>外部成交价：{{info.chxPrice}}</span> 
              </div>
            </el-col>
            <!-- <el-col :span="11">
              <div class="title">收款信息</div>
              <div class="info-item">收款人真实姓名：{{ info.payeeName }}</div>
              <div class="info-item">收款人支付宝账户：{{ info.payeeAlipayAccount }}</div>
            </el-col> -->
            <el-col :span="24" style="margin-top:15px">
              <div class="title">平台追差内容</div>
              <div class="info-item">申请回收商：{{ info.applyMerchantName }}</div>
              <div class="info-item">
                <span class="mr-30">申请金额：{{ info.arguePrice }}元 </span>
                <span class="mr-30">初审通过金额：{{ info.auditPrice }}元</span>
                <span class="mr-30">复核打款金额：{{ info.rePayPrice || '--' }}元</span>
              </div>
            </el-col>
          </el-row>
        </div>
        <div class="box gray">
          <div class="title">追差提交信息</div>
          <el-form ref="formRef" :model="formData" :rules="formRule" :disabled="type == 'detail'">
            <!-- <el-form-item label="售后方式：" prop="argueType">
              <el-radio-group v-model="formData.argueType">
                <el-radio :label="0">补差</el-radio>
                <el-radio :label="1">退货退款</el-radio>
              </el-radio-group>
            </el-form-item> -->
            <el-form-item label="追差金额：" prop="arguePrice">
              <el-input v-model="formData.arguePrice" style="width:100px" />
              元
            </el-form-item>
            <el-form-item label="追差原因：" prop="argueNote">
              <el-input v-model="formData.argueNote" type="textarea" :rows="3" />
            </el-form-item>
            <el-form-item label="追差凭证：" prop="images">
              <UploadImgOrVideo v-model="formData.images" ref="uploadImgOrVideo" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="visible = false">取消</el-button>
          <el-button v-if="type !== 'detail'" type="primary" @click.native="confirm">确定提交</el-button>
        </span>
      </el-dialog>
      <FunctionDia ref="functionDia" />
    </div>
  </template>
  
  <script>
  import _api from "@/utils/request";
  import UploadImgOrVideo from './UploadImgOrVideo.vue';
  import FunctionDia from './FunctionDia.vue';
  export default {
    components: { UploadImgOrVideo, FunctionDia },
    name: "ExternalApplication",
    data() {
      const arguePriceVaild = (rule, value, callback) => {
        const number = Number(value)
        if (number > this.info.chxPrice) callback(new Error('不得高于外部成交金额'))
        callback()
      }
      return {
        visible: false,
        row: {},
        info: {},
        loading: false,
        type: 'create', // create detail
        formData: {
          // argueType: '',
          arguePrice: '',
          argueNote: '',
          images: []
        },
        formRule: {
          // argueType: [{ required: true, message: '请选择售后方式', trigger: 'change' }],
          arguePrice: [
            { required: true, message: '请输入追差金额', trigger: 'change' },
            { validator: arguePriceVaild, trigger: 'change' }
          ],
          argueNote: [{ required: true, message: '请输入追责原因', trigger: 'change' }],
          images: [{ required: true, message: '请上传图片', trigger: 'change' }],
        }
      };
    },
    methods: {
      open(row, type = 'create') {
        this.row = row
        this.type = type
        this.formData = {
          // argueType: '',
          arguePrice: '',
          argueNote: '',
          images: []
        }
        // this.$refs['uploadImgOrVideo']?.init()
        this.getArgueDetail()
        this.$nextTick(() => {
          this.$refs['formRef']?.clearValidate()
          this.visible = true
        })
      },
      getArgueDetail() {
        this.loading = true
        const { argueNo, subArgueNo } = this.row
        _api.actionAprgdetail({
          argueNo: argueNo == '--' ? undefined : argueNo,
          subArgueNo: subArgueNo == '--' ? undefined : subArgueNo
        }).then(res => {
          this.info = res.data
          const { arguePriceHonor, argueNote, images, items, applyNote, arguePrice } = res.data
          if (this.type === 'create') {
            this.formData.images = images
            this.formData.arguePrice = arguePrice
            this.formData.argueNote = [...(items||[]), applyNote].filter(v => v).join('，')
          }
          if(this.type === 'anewcreate'){
          this.formData.images = images
          this.formData.arguePrice = arguePrice
          this.formData.argueNote = argueNote
        }
          if (this.type === 'detail') this.formData = { arguePrice: arguePriceHonor, argueNote, images }
        }).finally(() => {
          this.loading = false
        })
      },
      close() {
        this.visible = false
      },
      confirm() {
        this.$refs['formRef'].validate((vaild) => {
          if (vaild) {
            _api.actionAprgeneral({
              ...this.formData,
              argueNo: this.row.argueNo
            }).then(() => {
              this.$message.success('操作成功')
              this.close()
              this.$emit('confirm')
            })
          }
        })
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .box{
    padding: 10px;
    &.gray {
      background: rgb(245, 246, 250);
    }
  }
  .title{
    font-weight: bold;
    margin-bottom: 10px;
  }
  .info-item{
    margin-bottom: 8px;
    display: flex;
  }
  .mr-30{
    margin-right: 30px;
  }
  .ml-10{
    margin-left: 10px;
  }
  </style>